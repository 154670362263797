<template>
<v-app>
    <v-app-bar app>
        <v-img src="/img/PayConnect.png" alt="Pay-Connect" contain max-height="35" />
    </v-app-bar>
    <v-main style="padding: 0; margin-top: -15vh !important">
        <v-container fluid fill-height>
            <v-row align="center" justify="center">
                <v-img src="/img/401.png" alt="Unauthorized" contain max-height="80vh" />
            </v-row>
        </v-container>
    </v-main>
</v-app>
</template>

<script>
export default {
    name: 'Unauthorized',
}
</script>
